import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import Sidebar from "../../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function Admiralty({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Charleston South Carolina Maritime Lawyers | Tecklenburg, Jenkins & Jacoby, LLC"
        description="Tecklenburg, Jenkins & Jacoby are South Carolina’s premier maritime law
            firm. Defending and pursuing admiralty and maritime claims has been
            the predominant practice area of our Charleston, SC law firm since
            its inception."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/practice-areas/south-carolina-admiralty-maritime-law/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>Charleston South Carolina Maritime Lawyers</h1>
          {/* <h2>LITIGATION OF MARITIME OR ADMIRALTY CLAIMS</h2> */}
          <p>
            Tecklenburg, Jenkins & Jacoby is South Carolina’s premier maritime
            law firm. Defending and pursuing admiralty and maritime claims has
            been the predominant practice area of our Charleston, SC law firm
            since its inception. We litigate claims relating to:
          </p>
          <ul>
            <li>
              <h3>
                <a href="https://uscode.house.gov/view.xhtml?req=46+usc+30104&f=treesort&fq=true&num=4&hl=true&edition=prelim&granuleId=USC-prelim-title46-section30104">
                  Seamen’s Claims & The Jones Act
                </a>
              </h3>
            </li>
            <li>
              <h3>
                Cargo /{" "}
                <a href="https://uscode.house.gov/view.xhtml?req=46+usc+30701&f=treesort&fq=true&num=4&hl=true&edition=prelim&granuleId=USC-prelim-title46-section30701">
                  Carriage Of Goods By Sea Act
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://uscode.house.gov/view.xhtml?req=granuleid%3AUSC-prelim-title46-chapter303&saved=%7CNDYgdXNjIDMwMzAy%7CdHJlZXNvcnQ%3D%7CdHJ1ZQ%3D%3D%7C2%7Ctrue%7Cprelim&edition=prelim">
                  Death On The High Seas Act
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.dol.gov/owcp/dlhwc/lhwca.htm#.UHREglGK8SI">
                  Longshoremen’s and Harbor Workers Compensation Act (§905(b)
                  “third party” claims)
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www2.epa.gov/laws-regulations/summary-clean-water-act">
                  Vessel Pollution Claims,
                </a>{" "}
                including{" "}
                <a href="https://www2.epa.gov/enforcement/oil-pollution-act-opa-and-federal-facilities">
                  Oil Pollution Act (“OPA”),
                </a>{" "}
                <a href="https://www2.epa.gov/laws-regulations/summary-clean-water-act">
                  Clean Water Act (“CWA”),
                </a>{" "}
                and{" "}
                <a href="https://www.epa.gov/superfund/superfund-cercla-overview">
                  Comprehensive Environmental Response Compensation and
                  Liability Act (“CERCLA”)
                </a>
              </h3>
            </li>
            <li>
              <h3>Allisions / Collisions</h3>
            </li>

            <li>
              <h3>
                <a href="https://uscode.house.gov/view.xhtml?req=46+usc+31342&f=treesort&fq=true&num=2&hl=true&edition=prelim&granuleId=USC-prelim-title46-section31342">
                  Maritime Liens
                </a>
              </h3>
            </li>
            <li>
              <h3>Ship Arrests</h3>
            </li>
            <li>
              <h3>
                <a href="https://uscode.house.gov/view.xhtml?req=46+usc+31325&f=treesort&fq=true&num=5&hl=true&edition=prelim&granuleId=USC-prelim-title46-section31325">
                  Ship Mortgages & Maritime Lien Foreclosure
                </a>
              </h3>
            </li>
            <li>
              <h3>
                Personal Injury, including{" "}
                <a href="https://uscode.house.gov/view.xhtml?req=granuleid%3AUSC-prelim-title46-chapter305&saved=%7CNDYgdXNjIDMwNTAx%7CdHJlZXNvcnQ%3D%7CdHJ1ZQ%3D%3D%7C3%7Ctrue%7Cprelim&edition=prelim">
                  Vessel Owners Limitation Of Liability Act
                </a>
              </h3>
            </li>
          </ul>
          <h2>Investigation of Maritime or Admiralty Claims</h2>
          <p>
            Our attorneys assist in the investigation of all types of marine
            incidents, including pollution discharges, personal injury,
            collisions, allisions, cargo and vessel damage. It is common for our
            attorneys to be called to the scene of a serious marine incident,
            coordinate investigations and interact with government officials. We
            embrace the philosophy that early and thorough investigation ensures
            claims are properly administered from start to finish.{" "}
            <Link to="/contact">Contact an experienced attorney</Link> at
            Tecklenburg, Jenkins & Jacoby today.
          </p>
          <h2>Effective and Experienced in Maritime Business</h2>
          <p>
            Our admiralty attorneys represent businesses and vessel owners in
            nearly every type of legal difficulty that may occur at sea or on
            the waterfront. Areas of business maritime law practiced by
            Tecklenburg, Jenkins & Jacoby include:
          </p>
          <ul>
            <li>
              <h3>Vessel Documentation & Registration</h3>
            </li>
            <li>
              <h3>Marine Insurance</h3>
            </li>
            <li>
              <h3>Charter Parties</h3>
            </li>
            <li>
              <h3>
                <a href="https://uscode.house.gov/view.xhtml?req=46+usc+31325&f=treesort&fq=true&num=5&hl=true&edition=prelim&granuleId=USC-prelim-title46-section31325">
                  Ship Mortgages
                </a>
              </h3>
            </li>
            <li>
              <h3>Shipbuilding</h3>
            </li>
            <li>
              <h3>Sales Disputes</h3>
            </li>
            <li>
              <h3>Shipping Contracts</h3>
            </li>
            <li>
              <h3>Stevedore Contracts</h3>
            </li>
            <li>
              <h3>Coast Guard Issues</h3>
            </li>
            <li>
              <h3>Homeland Security Issues</h3>
            </li>
            <li>
              <h3>Environmental Issues</h3>
            </li>
          </ul>
          <h2>What is Maritime Law? (Also Called Admiralty)</h2>
          <p>
            <a href="https://www.law.cornell.edu/wex/south-carolina-admiralty-maritime-law">
              Admiralty
            </a>{" "}
            law (sometimes called “maritime law”) governs maritime disputes and
            offenses. Maritime law consists of both domestic laws governing
            maritime activities and private international laws. These maritime
            laws or “marine laws” regulate the legal relationships between
            private entities which operate vessels on the ocean. Admiralty law
            affects many aspects of maritime business including marine commerce,
            marine navigation, shipping, negligence/torts, sailors or seamen,
            and the transportation of passengers and goods by sea.
          </p>
          <p>
            <Link to="/contact">Contact Us</Link> For More Information
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AdmiraltySidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "south-carolina-admiralty-maritime-law" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
